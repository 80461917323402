<template>
  <a
    class="rotating-arrow"
    :class="{'rotating-arrow_rotated': !isOpen}"
    href="#"
    @click.prevent="isOpen = !isOpen"
  >
    <arrow-svg direction="top"></arrow-svg>
  </a>
</template>

<script setup lang="ts">
import 'sass/base/arrow.scss';

import {computed} from '#imports';
import ArrowSvg from 'svg/arrow.vue';

const props = defineProps({
  isOpen: {type: Boolean, required: true},
});

const emit = defineEmits<{
  (e: 'update:isOpen', value: boolean): void;
}>();

const isOpen = computed({
  get() {
    return props.isOpen;
  },
  set(val) {
    emit('update:isOpen', val);
  },
});
</script>
